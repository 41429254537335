/* src/NavBar/NavBar.css */

nav {
    background-color: #fff;
    padding: 1rem;
}

nav ul {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    margin: 0;
    padding: 0;
}

nav ul li {
    display: inline;
}

nav ul li a,
nav ul li button {
    background-color: #fff;
    color: #333;
    text-decoration: none;
    padding: 8px 16px;
    font-weight: bold;
}

nav ul li a:hover,
nav ul li button:hover {
    background-color: #555;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

/* Remove default button styles for the logout button */
nav ul li button {
    background: none;
    border: none;
    color: #333;
    font-size: 1rem;
    font-weight: bold;
    padding: 8px 16px;
}

nav ul li button:hover {
    background-color: #555;
    border-radius: 4px;
}
