/* Frontend/src/components/_variables.css */
/* _variables.css */

/* Color Variables */
:root {
    --primary-color: #007bff;
    --secondary-color: #25d366;
    --secondary-color-dark: #0df462;
    --warning-color: #ffc107;
    --danger-color: #dc3545;
    --danger-color-dark:#c82333;
    --reset-color:#6c757d;
    --reset-color-dark: #5a6268;
    --background-color: #fff;
    --border-color: #ddd;
    --text-color: #555;
    --hover-bg-color: #f1f1f1;
    --even-row-bg-color: #f9f9f9;
    --header-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    --placeholder-color: #aaa;
    --error-message-color: red;
    --success-message-color:green;
    --disable-background-color:#f5f5f5;
    --form-background-color: #f9f9f9;
}

/* Font Variables */
:root {
    --font-family: Arial, sans-serif;
    --font-size: 0.9rem;
}

/* Spacing Variables */
:root {
    --padding: 10px;
    --gap: 8px;
    --margin: 20px 0;
}
