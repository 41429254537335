@import './_variables.css';

/* General Styles */
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Form Container */
.form-container {
    max-width: 100%;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: var(--background-color);
    border-radius: 8px;
}

/* Form Row and Groups */
.form-row {
    padding: 40px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

/* Input Fields */
input, textarea, select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    height: 20;
    background-color: var(--background-color);
    color: var(--text-color);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus, textarea:focus, select:focus {
    border-color: var(--primary-color);
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

input:disabled, select:disabled {
    background-color: var(--disable-background-color);
    cursor: not-allowed;
    opacity: 0.7;
}

input::placeholder {
    color: var(--placeholder-color);
    font-style: italic;
}

.select-all{
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    height: 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.entry-name {
    margin-bottom: 8px; /* Add space between name and buttons */
    font-size: 16px;
}

.buyer-actions {
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    gap: 10px;
    margin-left: auto;
}

textarea {
    resize: vertical;
    min-height: 20px;
}

/* Buttons */
button {
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px;
    border: none;
}

button.primary {
    background-color: var(--primary-color);
    color: var(--background-color);
}

button.primary:hover {
    background-color: var(--secondary-color);
}

button.secondary:hover,
button.danger:hover,
button.reset:hover {
    background-color: var(--danger-color-dark);
}

button[title="Edit"] {
    background-color: var(--warning-color);
}

button[title="Delete"] {
    background-color: var(--danger-color);
}

button[title="WhatsApp"],
button[title="CustomWhatsApp"],
button[title="Intro"],
button[title="Proposal"],
button[title="Banner"],
button[title="Enquiry"],
button[title="Website"] {
    background-color: var(--secondary-color);
}

button.close-button {
    background: none;
    font-size: 1.5em;
    color: #333;
}

button.close-button:hover {
    color: var(--danger-color);
}

button[title] {
    margin-left: auto;
    color: white;
}

/* Messages */
.success-message {
    color: var(--success-message-color);
    margin-top: 10px;
}

.error-message {
    color: var(--error-message-color);
    margin-top: 10px;
}

/* Submit Button */
.submit-btn {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
    width: 100%;
}

/* Data Display */
.data {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.data-container {
    gap: 10px;
    margin-left: auto;
    display: flex;
    justify-content: center;
}

td {
    vertical-align: middle;
    text-align: left;
    padding: 10px;
    border: 1px solid #ddd;
}

/* Data Column: Email and Phone */
.data-column {
    display: flex;
    flex-direction: column; /* Stack content vertically */
    gap: 5px; /* Add spacing between items */
}

.data-item {
    font-size: 14px; /* Adjust font size */
    color: #333;
    word-wrap: break-word; /* Allow wrapping for long text */
}

/* Action Icons */
.action-icons {
    display: flex;
    gap: 10px; /* Add space between icons */
    justify-content: center; /* Center-align icons */
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 1200px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
}

.modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    background-color: #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-close:hover {
    background-color: red;
    color: white;
}

/* Responsive Layout */
@media (max-width: 1024px) {
    .form-row {
        grid-template-columns: repeat(2, 1fr); /* Adjust grid for medium screens */
    }

    .data-container {
        flex-direction: column; /* Stack content vertically */
        align-items: center;
    }
}

@media (max-width: 768px) {
    .form-row {
        grid-template-columns: 1fr; /* Single column on small screens */
    }

    .data {
        flex-direction: column; /* Stack name and actions vertically */
        align-items: center;
    }

    .buyer-actions {
        flex-wrap: wrap;
    }

    .modal-content {
        width: 90%; /* Reduce modal width */
        max-width: 600px;
    }

    .nav-links {
        flex-direction: column; /* Stack navigation links */
        align-items: center;
    }

    .nav-links li {
        margin: 10px 0;
    }
}


@media (max-width: 480px) {
    .form-container {
        padding: 10px;
    }

    button {
        font-size: 14px; /* Smaller buttons on tiny screens */
        padding: 8px;
    }

    input, textarea, select {
        font-size: 14px; /* Adjust input sizes */
        height: 40px;
    }

    table th, table td {
        font-size: 14px;
        padding: 5px;
    }
}
/* Table Styles */
table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    table-layout: auto;
}

table th, table td {
    word-wrap: break-word;
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
}

table th {
    background-color: var(--primary-color);
    color: var(--background-color);
    font-weight: bold;
}

table tr:nth-child(even) {
    background-color: #f9f9f9;
}

table tr:hover {
    background-color: #f1f1f1;
}

.table-container {
    max-height: 400px;  /* Adjust height as needed */
    overflow-y: auto;
    display: block;
}
thead {
    position: sticky;
    top: 0;
    background-color: #fff; /* Ensure the header has a background */
    z-index: 10; /* Ensure the header stays above the table rows */
}

/* Flex Layout for Buttons */
.button-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
}

/* Label Styling */
label {
    font-weight: bold;
    font-size: 14px;
    color: var(--text-color);
    display: block;
    margin-bottom: 5px;
    cursor: pointer;
}

/* Header and Navigation */
header {
    background-color: #007bff;
    padding: 10px 0;
}

.nav-links {
    list-style: none;
    display: flex;
    justify-content: center;
}

.nav-links li {
    margin: 0 15px;
}

.nav-links a {
    text-decoration: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
}

.nav-links a:hover {
    color: #ff6347;
}
